
import { Vue, Component } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import DocumentFiltersModel from '@/modules/document-filters/models/document-filters.model';
import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';
import CustomLoader from '@/modules/common/components/ui-kit/custom-loader.vue';
import StyledSearchbar from '@/modules/common/components/styled-searchbar.vue';
import USER_LEVELS from '@/modules/user/constants/user-levels.constant';
import AuthService, { AuthServiceS } from '@/modules/auth/auth.service';
import CompsetSettingsService, { CompsetSettingsServiceS } from '@/modules/compsets/compset-settings.service';
import HomeFiltersService, { HomeFiltersServiceS } from '@/modules/home/home-filters.service';
import getParentNonModalRoute from '@/modules/common/filters/no-modal-route.filter';
import { Location } from 'vue-router';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import HotelsService, { HotelsServiceS } from '../hotels.service';

@Component({
    components: {
        ModalWrapper,
        StyledSearchbar,
        CustomLoader,
    },
})
export default class HotelList extends Vue {
    @Inject(HotelsServiceS) hotelsService!: HotelsService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(CompsetSettingsServiceS) private compsetSettingsService!: CompsetSettingsService;
    @Inject(AuthServiceS) private authService!: AuthService;
    @Inject(HomeFiltersServiceS) homeFiltersService!: HomeFiltersService;
    @Inject(ClusterServiceS) private clusterService!: ClusterService;

    private filterQuery: string | null = null;

    get hotelList() {
        if (!this.filterQuery) {
            return this.hotelsService.myHotels;
        }

        const hotelNamePattern = new RegExp(this.filterQuery, 'i');
        return this.hotelsService.myHotels
            .filter(hotel => hotelNamePattern.test(hotel.name));
    }

    get currentHotel() {
        return this.userService.currentHotelId;
    }

    get myLevelName() {
        return this.userService.levelName;
    }

    get isViewAsChain() {
        return this.userService.viewAs === USER_LEVELS.CHAIN || this.userService.viewAs === USER_LEVELS.CLUSTER;
    }

    get isViewAsHotel() {
        return this.userService.viewAs === USER_LEVELS.HOTEL;
    }

    async changeHotel(hotelId: number) {
        if (this.userService.viewAs === USER_LEVELS.HOTEL && this.currentHotel === hotelId) {
            return;
        }

        const section = this.getCurrentSection().replace(/(hotel-list|settings)/, '') || '';

        await this.$router.push(`/hotel/${hotelId}/${section}`);

        this.clusterService.hotels = null;
        this.documentFiltersService.storeState.settings = new DocumentFiltersModel();
        this.compsetsService.storeState.compsets = null;
        this.compsetSettingsService.settingsActiveCompsetId = null;
        this.homeFiltersService.resetRatesProvider();

        await this.userService.switchCurrentHotel(hotelId);
        this.changeViewAs(USER_LEVELS.HOTEL, hotelId);
        this.authService.socketHandshake();
        this.clusterService.resetLoading();
    }

    private getCurrentSection() {
        if (this.$route.path.startsWith('/settings')) {
            return 'settings';
        }

        if (this.$route.path.startsWith('/hotel')) {
            return this.$route.path.split('/')[3];
        }

        return this.$route.path.split('/')[2];
    }

    private async replaceRouteToTopLevel() {
        const { userLevel } = this.userService;

        let newRoute = this.getCurrentSection();

        if (newRoute === 'settings') {
            newRoute = '';
        }

        if (newRoute === 'hotel-list') {
            newRoute = 'rates';
        }

        await this.$router
            .push({ name: `${userLevel}.${newRoute}`, params: { page: '1' } })

        // NOTE: Avoid showing navigation guard error
        //       when target route feature is disabled for user
            .catch(() => {});
    }

    async changeToTopLevel(closeModal: Function) {
        if (this.userService.isViewAsChain) {
            closeModal();
            return;
        }

        const routeName = this.$route.name || '';
        const { userLevel } = this.userService;

        this.changeViewAs(userLevel as USER_LEVELS);

        let targetLocation: Location;

        const isHotelLevelRoute = /\/hotel\//.test(this.$route.path);

        if (isHotelLevelRoute) {
            this.replaceRouteToTopLevel();
            return;
        }

        const isHomePage = routeName.includes('-home');

        if (!isHomePage) {
            const routeHierarchy = getParentNonModalRoute(this.$router).split('.');
            const oldUserLevel = routeHierarchy.shift();
            let targetRouteName = [userLevel, routeHierarchy].flat().join('.');

            if (oldUserLevel === 'hotel') {
                // NOTE: Enable when chain level will enabled
                // if (userLevel === 'chain') {
                //     targetRouteName += '.cluster';
                // }

                targetRouteName += '.hotel';
            }

            if (targetRouteName === 'chain' || targetRouteName === 'cluster') {
                targetRouteName += '-home';
            }

            targetLocation = {
                name: targetRouteName,
                params: {
                    hotelId: String(this.userService.currentHotelId),
                    clusterId: String(0),
                },
            };
        } else {
            targetLocation = {
                name: `${userLevel}-home`,
            };
        }

        this.$router
            .push(targetLocation)
            .catch(() => {}); // NOTE: Avoid guard errors;
    }

    changeViewAs(level: USER_LEVELS, id?: number) {
        this.userService.setViewAs(level, id);
    }

    afterType(query: string) {
        this.filterQuery = query;
    }
}
